import React from "react"
import { graphql } from "gatsby"
import { Layout } from "../components"
import { GatsbySeo, LocalBusinessJsonLd } from "gatsby-plugin-next-seo"

const Home = ({ data }) => {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark
  return (
    <Layout>
      <GatsbySeo
        title={frontmatter.seo_title}
        titleTemplate="CG Homeopathie - %s"
        description={frontmatter.seo_description}
      />
      <LocalBusinessJsonLd
        type="MedicalBusiness"
        id="https://www.cghomeopathie.nl/"
        name="CG Homeopathie"
        description={frontmatter.seo_description}
        url="https://www.cghomeopathie.nl/"
        telephone="06-43263629"
        address={{
          streetAddress: "Randhoeve 221",
          addressLocality: "Houten",
          postalCode: "3995 GA",
          addressCountry: "NL",
        }}
        geo={{
          latitude: "52.0360061",
          longitude: "5.166093",
        }}
        images={["https://www.cghomeopathie.nl/uploads/Chantal.jpg"]}
      />
      <h1>{frontmatter.title}</h1>
      <div
        dangerouslySetInnerHTML={{ __html: html }}
      />
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    markdownRemark(frontmatter: { slug: { eq: "/home" } } ) {
      html
      frontmatter {
        title
        seo_title
        seo_description
      }
    }
  }
`

export default Home
